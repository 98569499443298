import { handleActions, createAction } from 'redux-actions';
import { client } from 'cccisd-apollo';
import groupQuery from '../graphql/groups.graphql';

// This reducer handles fetching, formatting, and storing group data
//    in various structures -- tree, table, etc.

export const initialState = {
    groups: [], // raw groups array
    checked: [], // Checked nodes in Site Tree
    fieldChecked: [],
    evalChecked: [], // For Single Select
    expanded: [], // expanded nodes in Site Tree
    customChecked: false, // True if user has made changes to checked
    loaded: false,
    license: null,
};

// Actions
const SET_GROUPS = 'app/sites/SET_GROUPS';
const SET_CHECKED = 'app/sites/SET_CHECKED';
const SET_EXPANDED = 'app/sites/SET_EXPANDED';
const SET_LOADED = 'app/sites/SET_LOADED';
const SET_FIELD_CHECKED = 'app/sites/SET_FIELD_CHECKED';
const SET_LICENSE = 'app/sites/SET_LICENSE';

// Action Creators
export const setGroups = createAction(SET_GROUPS);
export const setChecked = createAction(SET_CHECKED);
export const setExpanded = createAction(SET_EXPANDED);
export const setLoaded = createAction(SET_LOADED);
export const setFieldChecked = createAction(SET_FIELD_CHECKED);
export const setLicense = createAction(SET_LICENSE);
// Thunks
export const loadGroups = () => {
    return async (dispatch, getState) => {
        const response = await client.query({
            query: groupQuery,
            variables: {
                limit: -1,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });
        dispatch(
            setGroups([
                ...response.data.groups.siteList,
                ...response.data.groups.agencyList,
                ...response.data.groups.organizationList,
                ...response.data.groups.collaboratorList,
            ])
        );
        localStorage.setItem('numSites', response.data.groups.siteList.length);
        dispatch(setLoaded(true));
    };
};

// Reducers
export default handleActions(
    {
        [SET_GROUPS]: (state, action) => ({
            ...state,
            groups: action.payload,
        }),
        [SET_CHECKED]: (state, action) => ({
            ...state,
            checked: action.payload,
            customChecked: true,
        }),
        [SET_EXPANDED]: (state, action) => ({
            ...state,
            expanded: action.payload,
        }),
        [SET_LOADED]: (state, action) => ({
            ...state,
            loaded: action.payload,
        }),
        [SET_FIELD_CHECKED]: (state, action) => ({
            ...state,
            fieldChecked: action.payload,
            customChecked: true,
        }),
        [SET_LICENSE]: (state, action) => ({
            ...state,
            license: action.payload,
        }),
    },
    initialState
);
